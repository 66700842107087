import React, { useEffect, useState } from "react";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import styled from "styled-components";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const Section = styled.section`
  width: 100%;
  float: left;
  display: flex;
  margin-bottom: 40px;
  justify-content:space-between;
`;
const Tab = styled.div`
  font-size: 20px;
  cursor: pointer;
  background: inherit;
  width: 100%;
  border: 0;
  outline: 0;
  padding: 20px;
  ${({ active }) =>
    active &&
    `
    background: #F2FFFA 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    overflow: overlay;
  `}
`;
const Header = styled.h4`
  font-family: Roboto-Medium;
    font-size: 20px;
    color: #333;
    padding-bottom: 5px;
    width: 100%;
    float: left;
    margin-bottom: 0;
    line-height: normal;
}
`;
const Paragraph = styled.p`
flex: 1 1 auto;
color: #416183 !important;
font-family: Roboto-Regular;
line-height: 1;
margin-bottom: 0px;
font-size: 20px;
width:100%;
float:left;
}
`;
const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  flex: 0 0 49%;
    max-width: 49%;
`;
const ContentGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  flex: 0 0 49%;
  max-width: 49%;
`;
const Tabalign = styled.div`
width: 100%;
display: flex;
flex-wrap: wrap;
height: 100%;
align-items: center;
`

const section_one = require("../../assets/img/how-it-works/hw_sec_1.png");
const section_two = require("../../assets/img/how-it-works/hw_sec_2.png");
const section_three = require("../../assets/img/how-it-works/hw_sec_3.png");


const ldSchema = {
  "@context": "https://schema.org/",
  
    "@type": "HowTo", 
  
    "name": "How workativ Assistant Works",
  
    "description": "Go live with an advanced chatbot with process automation in 3 steps. No coding, we promise!",
  
    "step": [{
  
      "@type": "HowToStep",
  
      "text": "Build Dialogs",
  
      "url": "https://workativ.com/assistant/how-it-works#section-1" 
 },

{
  
    },{
  
      "@type": "HowToStep",
  
      "text": "Automate Process",
  
      "name": "Automation Designer",
  
      "url": "https://workativ.com/assistant/how-it-works#section-2"
  
    },{
  
      "@type": "HowToStep",
  
      "text": "Deploy",
  
      "name": "Deploy",
  
      "url": "https://workativ.com/assistant/how-it-works#section-3",
  
    }]    
  };
export default function FeatureHomePage() {

  return (
    <>
      <TitleAndMetaTags
        title="How it works | Workativ Automate"
        description="Learn how to automate workflows in three simple steps. Sign up for free."
        keywords={["workativ how it works"]}
        ogImage={section_one}
        schema={ldSchema}
      />
      <Container>
        <div className="automate_product">
        <Layout backgroundColor={"how_it_section_bg automate_homepage"} logoFor="AUTOMATE" product="AUTOMATE">
          <HowItsWorkPage />
          <div className="tabgroup_bg_white automate_homepage">
                <TabGroup
                  header="What you can do"
                />
              </div>
              <div className="automate_homepage">
          <RequestForm product="automate" content="We need your feedback, inputs, and experience to help us build a great product!" AutomateCTA automateContent autoamteButton automateName isFooterForm={true} />
          </div>
        </Layout>
        </div>
      </Container>
    </>
  );
}

function HowItsWorkPage() {
  return (
    <section className="how_it_section how_it_page_content automate_homepage">
      <div className="how_it_section_page">
        <div className="container">
          <div className="main-slider-left">
            <h1>
            Simple, yet powerful <br></br>No coding required
            </h1>
          </div>
          <div className="row">
            {/*How it Works Content*/}
            <div className="x_scroll_page">
              <div className="how_it_works_header">
                <div className="benefits-workplace-content how_it_content how_it_content_before">
                  <img
                     src={require("../../assets/automate/how-it-works/howitworks_step_1.png")}
                    alt="Select triggers"
                  />
                  <h3>
                  <AnchorLink
offset={180}
className="url_manipulation scroll_page"
href="#section-1"
>
<span>01.</span>Select triggers
</AnchorLink>
                    {/* <a className="scroll_page" href="#section-1">
                     
                    </a> */}
                  </h3>
                  <p>
                  Select condition to trigger the workflow e.g., if ‘contact is added’ in ServiceNow.
                  </p>
                </div>
              </div>
              {/*How it Works Content*/}
              <div className="how_it_works_header">
                <div className="benefits-workplace-content  how_it_content how_it_content_before">
                  <img
                     src={require("../../assets/automate/how-it-works/howitworks_step_2.png")}
                    alt="Add actions"
                  />
                  <h3>
                  <AnchorLink
offset={180}
className="url_manipulation scroll_page"
href="#section-2"
>
<span>02.</span>Add actions
</AnchorLink>
                   
                  </h3>
                  <p>
                  Add actions for the workflow e.g., then ‘create new record’ in Salesforce
                  </p>
                </div>
              </div>
              {/*How it Works Content*/}
              <div className="how_it_works_header">
                <div className="benefits-workplace-content how_it_content">
                  <img
                    src={require("../../assets/automate/how-it-works/howitworks_step_3.png")}
                    alt="Activate"
                  />
                  <h3>
                  <AnchorLink
offset={180}
className="url_manipulation scroll_page"
href="#section-3"
>
<span>03.</span>Activate</AnchorLink>
                  
                  </h3>
                  <p>
                  Test and activate workflow to<br></br> go-live.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <h6 className="how_3_steps">
          Go live in 3 steps.
          </h6>
        </div>
      </div>
    </section>
  );
}

const image5 = require("../../assets/automate/how-it-works/howitworks_sec_2_img_1.png");
const image6 = require("../../assets/automate/how-it-works/howitworks_sec_2_img_2.png");

const types = [
    {
      header: "Simple Workflow",
      content: "Create single step actions between two apps.",
      image: image5,
      uuid: "a",
      alt: "multi step workflow automation",
    },
    {
      header: "Complex Workflow",
      content:
        "Create multi-step workflows, add conditions, and actions across several applications.",
      image: image6,
      alt: "complex workflow automation",
    },
  ];

function TabGroup ({header}) {
  const [active, setActive] = useState(types[0]);
  return (
    <>
      <div className="tabgroup_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Section>
                <ButtonGroup className="col-lg-6 col-md-6 col-12 pl-0">
                <h2>{header}</h2>
                <Tabalign>
                  {types.map((type) => (
                    <Tab
                      key={type}
                      active={active === type}
                      onClick={() => setActive(type)}
                    >
                      <Header>{type.header}</Header>
                      <Paragraph>{type.content}</Paragraph>
                    </Tab>
                  ))}
                  </Tabalign>
                </ButtonGroup>
                <ContentGroup className="col-lg-6 col-md-6 col-12 pr-0">
                  <div className="image_tabgroup">
                    <img src={active.image} alt={active.alt}/>
                  </div>
                </ContentGroup>
              </Section>
            </div>
          </div>
        </div>
      </div>

      <>
      <div className="mobile_view_tab">
      <div className="mob_accordian mob_accordian_faq">
        <section className="accordian_landing trial_accordian_landing">
          <div className="container">
            <h2>
              {header}
            </h2>
            <div className="col-12 pl-0 faq_landing">
              <Accordion
                allowZeroExpanded={true}
                id="accordion"
                className="accordion pl-0"
                preExpanded={["a"]}
              >
                <div className="accordion-wrapper pricing_faq">
                  {types.map((data) => (
                    <AccordionItem uuid={data.uuid}>
                      <AccordionItemHeading>
                        <AccordionItemButton>{data.header}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p
                          dangerouslySetInnerHTML={{ __html: data.content }}
                        />
                          <div className="image_tabgroup">
                    <img src={data.image} alt={data.alt}/>
                  </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </div>
              </Accordion>
            </div>
          </div>
        </section>
      </div>   </div>
      </>
    </>
  );
}
